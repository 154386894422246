import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useKitchensByBrandId } from 'hooks/kitchens'
import React from 'react'
import { useTranslation } from 'react-i18next'
import _keyBy from 'lodash/keyBy'
import { useLanguage } from 'contexts/language'

export default function KitchenSelector({
  size = 'small',
  brandId,
  value,
  onChange,
  disabled,
  minWidth,
}) {
  const { t } = useTranslation()
  const { kitchens, isLoading } = useKitchensByBrandId(brandId)
  const kitchensByIds = React.useMemo(() => _keyBy(kitchens, '_id'), [kitchens])
  const { setLanguage } = useLanguage()

  const handleChange = (e) => {
    const selectedKitchenId = e.target.value
    onChange(selectedKitchenId)
    const selectedKitchen = kitchensByIds[selectedKitchenId]
    if (selectedKitchen && selectedKitchen.languages.length > 0) {
      setLanguage(selectedKitchen.languages[0])
      console.log('selectedKitchen.language', selectedKitchen.languages[0])
  }

  }

  React.useEffect(() => {
    if (!isLoading && kitchens?.length) {
      onChange(kitchens[0]._id)
    }
  }, [isLoading, kitchens, onChange])

  if (isLoading || !kitchens?.length) return null

  return (
    <FormControl size={size} sx={{ mx: 1, minWidth }} disabled={disabled}>
      <InputLabel id="kitchen-select-label">{t('kitchen')}</InputLabel>
      <Select
        labelId="kitchen-select-label-label"
        id="kitchen-select-label"
        value={value || t('all')}
        label={t('kitchen')}
        onChange={handleChange}
        disabled={disabled}
        displayEmpty
        renderValue={(kitchenId) =>
          kitchenId !== t('all')
            ? kitchensByIds?.[kitchenId]?.operationalName
            : t('all')
        }
      >
        {kitchens?.map((kitchen) => (
          <MenuItem value={kitchen._id} key={kitchen._id}>
            {kitchen.operationalName}
          </MenuItem>
        ))}
        {/* 
        // right now we should not allow to select all kitchens - especially for the analytics
        <MenuItem value={null}>{t('all')}</MenuItem> 
        */}
      </Select>
    </FormControl>
  )
}
