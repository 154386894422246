export default {
  virtual_waiter: 'מלצר וירטואלי',
  loading: 'בטעינה',
  kitchens_list: 'מטבחים שאיתנו',
  register_or_sign_in: 'התחברות',
  login_to_eat_id: 'חיבור ל-EAT ID שלי',
  create_your_eat_id: 'יצירת EAT ID',
  login_with_no_verifcation: 'התחברות',
  already_registered: 'את\\ה כבר מחובר',
  signed_in_user: 'משתמש\\ת מחובר\\ת',
  anonymus: 'אנונימי',
  signout: 'התנתקות',
  logout: 'התנתקות',
  update_culinary_id: 'עדכון ת"ז קולינרית',
  tofull_menu: 'דילוג לכל המנות',
  yes: 'כן',
  no: 'לא',
  other: 'אחר',
  send: 'שליחה',
  enter: 'כניסה',
  im_done: 'סיימתי',
  continue: 'המשך',
  done: 'סיום',
  next: 'הבא',
  back: 'חזרה',
  next_question: 'שאלה הבאה',
  add: 'הוספה',
  change: 'שינוי',
  duplicate: 'שכפול',
  search: 'חיפוש',
  choose: 'בחירה',
  close: 'סגירה',
  locale: 'בחירת שפה',
  defaultPhonenumberCountry: 'מדינה',
  elaborate: 'פירוט',
  choose_relevant_options: 'יש לבחור את כל האפשרויות הרלוונטיות',
  you_dont_eat_specific_foods: 'דברים שלא מתקרבים לצלחת שלך',
  allergies: 'אלרגיות',
  no_specific_diet: 'אין תזונה ספציפית',
  do_you_have_a_specific_diet: 'האם יש לך תזונה ספציפית?',
  diets: 'תזונה ספציפית',
  diets_step_title: 'האם יש לך תזונה ספציפית?',
  you_dont_eat_specific_foods_step_title: 'אילו דברים לא מתקרבים לצלחת שלך?',
  things_i_avoid_or_reduce_step_title: 'אילו מוצרים תירצו להימנע או להפחית?',
  allergies_step_title: 'אילו אלרגיות יש לך?',
  no_food_restrictions: 'אין הגבלות מיוחדות',
  things_i_avoid_or_reduce: 'מנסה להמנע ממזונות מסויימים',
  im_allergic: 'יש לי אלרגיה',
  things_i_dont_eat: 'מזונות שאני לא אוכל\\ת',
  filters: 'סינון',
  any_restrictions: 'האם יש לך הגבלות באוכל?',
  any_preferences: 'האם יש לך העדפות באוכל?',
  preference_step_subtitle: 'יש למלא את האפשרויות הרלוונטיות',
  ask_for_changes: 'המלצה עבורך',
  more_info: 'עוד פרטים',
  my_reccomendations_for_you: 'ההמלצות עבורך',
  ask_without_the: 'לבקש בלי',
  ingredients_selector_placeholder: 'מה לא מתקרב לצלחת שלך?',
  coming_soon: 'בקרוב',
  no_options: 'לא נמצאו אפשרויות',
  description: 'תיאור',
  shortDescription: 'תיאור קצר',
  longDescription: 'הסבר מלצר',
  warning: 'אזהרה',
  price: 'מחיר',
  default_price: 'מחיר',
  options: 'אופציות',
  sidedish: 'מנות צד',
  changes: 'שינויים',
  sizes: 'גדלים',
  ingredients: 'רכיבים',
  allergens: 'אלרגנים',
  kitchen_warning: 'אזהרת המטבח',
  kitchenType: 'סוג מטבח',
  hotel: 'מלון',
  foodService: 'הסעדה מוסדית',
  restaurant: 'מסעדה',
  conference: 'כנס',
  menu_options: 'תפריטים לבחירתך',
  choice: 'לבחירתך',
  addables: 'תוספות',
  addableComponents: 'תוספות',
  expand: 'להרחיב',
  collapse: 'לכווץ',
  enter_phone_number: 'הזנת מספר טלפון',
  login_plea: 'שנשמור את ההעדפות שלך?',
  login_plea_subtext: 'נודר, לא מספרים לאף אחד',
  login_plea_comment: '(נשתף רק מה אתם יכולים לאכול)',
  standalone_login_plea: 'שנשמור את ההעדפות שלך?',
  standalone_login_plea_subtext: 'צרו תעודת זהות קולינרית להתאמה אישית',
  no_login: 'ללא התחברות',
  dish_possible_changes: 'שינויים אפשריים למנה',
  ingredient: 'רכיב',
  dish: 'מנה',
  remove: 'הסרה',
  put_aside: 'לשים בצד',
  no_menus_relevant_now: 'לא נמצא תפריט מוגש לשעה זו',
  menus_in_other_working_hours: 'תפריטים מוצעים בשעות הפעילות',
  size_type_Small: 'קטן',
  size_type_Medium: 'בינוני',
  size_type_Large: 'גדול',
  size_type_glass: 'כוס',
  size_type_pitcher: 'קנקן',
  size_type_bottle: 'בקבוק',
  size_type_shot: 'שוט',
  size_type_Half: 'חצי',
  size_type_full: 'שלם',
  size_type_third: 'שליש',
  size_type_jigger: 'מנה',
  basic_ingredients: 'רכיבים כלולים',
  excludable_ingredients: 'רכיבים שניתן להוציא',
  putaside_ingredients: 'רכיבים שאפשר לשים בצד',
  choice_ingredients: 'רכיבי בחירה',
  sideDish_ingredients: 'רכיבי מנות צד',
  addable_ingredients: 'רכיבים הניתנים להוספה',
  addableDishes_ingredients: 'מנות הניתנות להוספה',
  translated_language: 'מתורגם לעברית',
  tabs: {
    admin: {
      foodGroups: 'קבוצות אוכל',
      organizations: 'ארגונים',
      ingredients: 'רכיבים',
      diets: 'דיאטות',
      allergens: 'אלרגנים',
      dishSizes: 'גדלי מנות',
      translations: 'תרגומים',
      sites: 'אתרים',
      manufacturers: 'ייצרנים',
      analytics: 'אנליטיקות',
      questionnaires: 'שאלונים',
      ingredientProperties: 'מאפייני רכיב',
      ingredientPropertiesGroups: 'קבוצות מאפייני מרכיב',
      ingredientGroups: 'קבוצות מרכיבים',
      foodTypes: 'סוגי מאכלים',
      foodTypeGroups: 'קבוצות סוגי מאכלים',
      manufacturedRecipes: 'מוצרים קנויים',
    },
    customers: {
      overview: 'סקירה כללית',
      organization: 'ארגון',
      generalInfo: 'מידע כללי',
      kitchens: 'מטבחים',
      employees: 'עובדים בארגון',
      kitchenManagement: 'ניהול מטבח',
      recipes: 'מתכונים',
      dishes: 'מנות',
      menus: 'תפריטים',
      categories: 'קטגוריות',
      offerings: 'הצעות הערך',
      questions: 'שאלות',
      inventoryItems: 'רכיבי מלאי',
      inventoryProducts: 'מוצרי מלאי',
      translations: 'תרגומים',
      services: 'ניהול סרביס יומי',
      servicesMenusPlanner: 'ניהול תפריטים שבועי',
    },
  },
  change_menu: 'תפריטים שאנחנו מציעים',
  settings: 'הגדרות',
  connect: 'קבוצה',
  my_list: 'הרשימה שלי',
  profile: 'העדפות',
  deal: 'DEAL',
  empty_components_restriction_selector: 'ניתן להקליד סוג אוכל, רכיבים או טעמים',
  empty_order: 'לא נבחרו אף מנות',
  order_summary: 'סיכום הזמנה',
  complete_order: 'אישור',
  edit_ingredient: 'ערוך רכיב',
  create_ingredient: 'צור רכיב',
  _id: 'מזהה',
  name: 'שם',
  translation_heb: 'תרגום לעברית',
  synonyms: 'שמות נוספים',
  tags: 'תגיות',
  food_groups: 'קבוצות אוכל',
  is_searchable: 'ניתן לחיפוש',
  sub_ingredients: 'תת רכיבים',
  excluded_in_diets: '??',
  what_would_you_like: 'מה נשמע לכם טוב?',
  you_can_tag_all_that_seems_to_your_liking: 'אנא סמנו מנות שמדברות אליכם',
  my_order_title_no_choices: 'אין מנות שעניינו אותך?',
  my_order_title: 'ההזמנה דרכי בקרוב!',
  my_order_subtitle: '(קראו למלצר והזמינו דרכו את בחירתכם...)',
  new_dish: 'מנה חדשה',
  new_recipe: 'מתכון חדש',
  new_category: 'new category',
  new_brand: 'רשת חדשה',
  categories_dishes: 'מנות הקטגוריה',
  image_URL: 'כתובת התמונה',
  categories: 'קטגוריות',
  create_ne_category: 'צור קטגוריה חדשה',
  excludible_ingredients: 'רכיבים שניתן להוציא',
  delete: 'מחיקה',
  reset: 'איפוס',
  save: 'שמירה',
  cancel: 'ביטול',
  are_you_sure_you_want_to_delete_this_dish: 'האם את\\ה בטוח\\ה שאת\\ה רוצה למחוק את המנה',
  is_possible_to_putaside: 'ניתן לשים בצד',
  added_price: 'תוספת מחיר',
  new_kitchen: 'מטבח חדש',
  kitchen: 'מטבח',
  kitchens: 'מטבחים',
  website: 'אתר אינטרנט',
  image: 'תמונה',
  address: 'כתובת',
  intentions: 'סוג מסעדה',
  currency: 'מטבע',
  dishes: 'מנות',
  add_new_dish: 'הוספת מנה',
  recipe: 'מתכון',
  recipes: 'מתכונים',
  add_new_recipe: 'הוספת מתכון',
  menu: 'תפריט',
  menus: 'תפריטים',
  new_menu: 'תפריט חדש',
  menu_categories: 'קטגוריות של התפריט',
  you_can_drag_and_drop_dishes_to_reorder_them: 'ניתן לגרור ולהזיז את סדר המנות',
  you_can_drag_and_drop_categories_to_reorder_them: 'ניתן לגרור ולהזיז את סדר הקטגוריות',
  category: 'קטגוריה',
  brand: 'רשת',
  brands: 'רשתות',
  add_category: 'הוספת קטגוריה',
  add_subcategory: 'הוספת תת-קטגוריה',
  edit_category: 'עריכת קטגוריה',
  category_dishes_linking: 'קישור מנות לקטגוריה',
  sort_categories: 'סידור קטגוריות',
  create_your_first_category: 'יצירת הקטגוריה הראשונה של התפריט',
  add_station: 'הוספת עמדה',
  add_dish: 'הוספת מנה',
  delete_menu: 'מחיקת תפריט',
  are_you_sure_you_want_to_delete_this_menu: '\\ה בטוח\\ה שאת\\ה רוצה למחוק את התפריטהאם את',
  personal_preferences: 'מותאם אישית',
  you_dont_have_any_preferences: 'אפשר להתאים לך אישית את התפריט?',
  would_you_like_to_personalize_the_menu: 'זה לוקח ממש כמה שניות',
  personalize: 'להתאמה אישית',
  customer_ingredients_and_recipes_selector_placeholder: 'חיפוש רכיבים ומתכונים שלך',
  empty_components_ingredients_and_recipes_selector: 'טרם נבחרו רכיבים ומתכונים',
  add_subingredients: 'הוספת רכיבים',
  delete_dish: 'מחיקת מנה',
  composition: {
    name: 'הרכב המנה',
    basic: 'רכיבים בסיסיים',
    choices: 'רכיבים של בחירה',
    addables: 'רכיבים שניתן להוסיף',
    sideDishesChoices: 'מנות צד לבחירה',
    modifiers: {
      putAsidable: 'ניתן לשים בצד',
      removable: 'ניתן להסרה',
      additionalPrice: 'תוספת למחיר',
      allowChoiceOfNone: 'ללא',
      defaultChosen: 'ברירת מחדל',
    },
  },
  add_menu: 'הוספת תפריט',
  add_menuInstance: 'הוספת תפריט',
  are_you_sure_you_want_to_delete_this_category: 'האם אתם בטוחים שאתם רוצים למחוק את הקטגוריה?',
  delete_category: 'מחיקת קטגוריה',
  recipes_and_ingredients: 'מתכונים ורכיבים',
  inventoryItem: 'רכיב מלאי',
  inventoryItems: 'רכיבי מלאי',
  inventoryProduct: 'מוצר מלאי',
  inventoryProducts: 'מוצרי מלאי',
  side_dishes: 'מנות צד',
  workingHours: 'שעות פעילות',
  start_time: 'התחלה',
  end_time: 'סיום',
  drinks: 'משקאות',
  upload_image: 'העלאת תמונה',
  drag_n_drop_placeholder: 'לבחירת תמונה יש לגרור תמונה או ללחוץ כאן',
  choiceOfNone: 'ללא',
  advances_choices_modifier_options: 'הגדרות בחירה מתקדמות',
  modifier_min_choices_count: 'מינימום לבחירה',
  modifier_max_choices_count: 'מקסימום לבחירה',
  clear: 'נקה',
  employees: 'עובדים בארגון',
  diet: 'דיאטה',
  food_group: 'קבוצת אוכל',
  organization: 'ארגון',
  employee: 'עובד ארגון',
  login: {
    login_with_google: 'התחברות עם Google',
    login: 'התחברות',
    register: 'הרשמה',
    channel: 'ערוץ הזדהות',
    email: 'אימייל',
    password: 'סיסמא',
    sms: 'sms',
    call: 'הודעה קולית',
    whatsapp: 'WhatsApp',
    enter: 'כניסה',
    verification_code: 'קוד הזדהות',
    failed_recieved_code: 'לא התקבל קוד?',
    send_again: 'שליחה חוזרת',
    fix_number_label: 'יש צורך בתיקון במספר הטלפון?',
    fix_number_request: 'תיקון הזדהות',
    code_expires_in: 'קוד ההזדהות יפוג תוקף בעוד',
    code_sent_valid_time: 'קוד ההזדהות שנשלח תקף ל-10 דק\'',
    didnt_recieve_verification_code: 'לא קיבלת קוד אימות?',
    phone_number_error: 'ישנה תקלה במספר הטלפון',
    verification_code_error: 'קרתה תקלה בקוד ההזדהות'
  },
  sec: 'ש\'',
  error: 'תקלה',
  try_again: 'יש לנסות שוב',
  allergy_general_warning: 'הבריאות שלכם חשובה לנו.  אם יש לכם רגישות למזון, אנא אשרו כי תעדכנו את הצוות במקום בנוסף לבדיקה עם המלצר הווירטואלי. יכולים להיות שינויים שאינם ידועים לנו.',
  accepted_and_read: 'הבנתי, תודה!',
  customer_ingredients_selector_placeholder: 'חיפוש רכיבים',
  customer_foodgroups_selector_placeholder: 'חיפוש קבוצות אוכל',
  phonenumber_must_be_atleast_8_digits: 'מספר הטלפון חייב להכיל לפחות 8 ספרות',
  saved: 'שמורים',
  categorized_dish_type: 'סיווג סוג המנה',
  all: 'הכל',
  food: 'אוכל',
  drink: 'משקאות',
  dessert: 'קינוחים',
  specials: 'ספיישלים',
  terms_of_use_and_privacy_policy_label: 'הרשמה/התחברות למערכת מהווה הסכמה',
  i_agree_to_the: 'אני מסכימ\\ה',
  terms_of_use: 'לתנאי השימוש',
  '&': 'ו-',
  privacy_policy: 'תנאי פרטיות',
  operationalName: 'שם תפעולי',
  displayName: 'תצוגה ללקוחות',
  availableHours: 'שעות פעילות',
  in_use: 'בשימוש',
  dishName: 'שם מנה',
  phonenumber: 'מספר טלפון',
  role: 'תפקיד',
  foodGroups: 'קבוצות אוכל',
  subIngredients: 'תתי רכיבים',
  contains: 'מכיל',
  mayContain: 'עלול להכיל',
  producedFrom: 'מיוצר מ',
  mayContainIngredients: 'עלול להכיל רכיבים',
  mayContainFoodGroups: 'עלול להכיל קבוצות אוכל',
  producedFromIngredients: 'מיוצר מרכיבים',
  producedFromFoodGroups: 'מיוצר מקבוצות אוכל',
  excludedInDiets: 'לא מתאים לדיאטות',
  computed_details: 'נתונים מחושבים',
  actions: 'פעולות',
  new: 'חדש',
  title: 'כותרת',
  subtitle: 'כותרת משנה',
  are_you_sure_you_want_to_delete_this_item: 'האם אתם בטוחים שאתם רוצים למחוק פריט זה?',
  category_type: 'סוג קטגוריה',
  email_or_password_incorrect: 'שם משתמש או סיסמא שגויים. אפשר לנסות שוב?',
  login_attempt_failed: 'ניסיון התחברות נכשל',
  category_new_or_imported: 'מקושר או חדש',
  link_category_to_category_collection: 'יצירת קטגוריה מקושרת',
  category_hard_link: 'קישור חזק',
  or: 'או',
  create_new_category: 'יצירת קטגוריה חדשה',
  linked_categories: 'קטגוריות מקושרות',
  category_details: 'פרטי הקטגוריה',
  no_dishes_fit_for_diner_category_type: 'לא נמצאו מנות המותאמות אלייך.',
  no_dishes_found_with_search: 'לא נמצאו מנות בחיפוש המבוקש',
  show_filtered_dishes: 'המנות בכל זאת יוצגו לך עם הסיבה שהמנה לא מתאימה.',
  filtered_out_dish: 'מנה סוננה כי מכילה',
  diner_allergy_consent: 'קראתי ואישרתי',
  all_choices_filtered_out: 'כל רכיבי הבחירה לא מתאימים לך',
  filtered_out_reasons: 'סיבות לאי התאמה',
  dish_contains: 'המנה מכילה',
  dish_not_verified: 'רכיבי המנה לא אומתו וכן מסוננות כדי להימנע מטעויות',
  dish_not_active: 'המנה לא זמינה',
  no_dishes_linked_to_kitchen: 'לא נבחרו מופעי מנות למטבח זה',
  dishInstance: 'מופע מנה',
  kitchens_list_for_management: 'רשימת המטבחים הניתנים לניהול',
  dishes_potential: 'מנות פוטנציאליות להגשה',
  no_dishes_chosen_yet: 'עוד לא נבחרו מנות לקטגוריה זו',
  dish_not_linked_to_any_category_served: 'המנה לא מוגשת באף קטגוריה המקושרת למטבח',
  kitchen_tags: 'תגיות מטבח',
  kitchenTags: 'תגיות מטבח',
  new_kitchenTag: 'תגית מטבח חדשה',
  dishKitchenTags: 'תגיות מנה',
  supported_languages: 'שפות נתמכות',
  change_language: 'שינוי שפה',
  phoneNumber: 'מספר טלפון',
  allow_only_in_specific_dates_range: 'האם יש הגבלה לתאריכים ספציפיים?',
  availableDates: 'טווח תאריכים לזמינות המנה',
  fromDate: 'מתאריך',
  toDate: 'לתאריך',
  is_active: 'פעילה',
  warnings: 'אזהרות',
  allow_only_in_specific_hours_in_days: 'האם יש הגבלה לשעות בימים מסויימים?',
  availableTimes: 'טווחי שעות זמינות',
  allow_only_in_specific_days_and_times: 'הצגה רק בימים ושעות ספציפיות',
  availabilityHoursInDays: 'זמינות בימים ספציפיים',
  no_limit: 'ללא הגבלה',
  begining_of_time: 'תחילה הזמן',
  end_of_time: 'לנצח',
  none: 'ריק',
  select_all: 'בחר הכל',
  unselect_all: 'איפוס בחירה',
  empty_potential_dishes_filtered: 'לא נמצאו מנות נוספות להוספה בסנונים אלו',
  min: 'מינ\'',
  max: 'מקס\'',
  add_dishes: 'הוספת מנות',
  dishesInstances: 'מנות המוגשות על ידי המטבח',
  dishSizes: 'גדלים אפשריים של המנה',
  allow_a_few_dish_sizes: 'אפשרות כמה גדלים של המנה',
  sizeUnit: 'כמות',
  sizeAmount: 'יחידות מידה',
  show_only_missing_translations: 'רק תרגומים חסרים',
  export_to_menu: 'ייצוא לתפריט',
  export: 'ייצוא',
  no_category_name: 'ללא קטגוריה',
  minutes: 'דקות',
  no_start_date: 'ללא תאריך התחלה',
  no_end_date: 'ללא תאריך סוף',
  add_times_and_days_range: 'הוספת טווחי זמנים וימים',
  add_dates_range: 'הוספת טווח תאריכים',
  different_dish_sizes_question: 'האם יש כמה גדלים שונים למנה?',
  faithRestriction: 'מגבלה מטעמי דת',
  faithRestrictions: {
    question: 'האם יש לך מגבלות מטעמי דת?',
    kosher: 'כשרות',
    kosher_certified_kitchen: 'כשר עם תעודה',
    kosher_no_certificate: 'כשר ללא תעודה',
    kosher_dishes: 'מנות כשרות',
    halal: 'בשר חלאל',
    halal_certification: 'חלאל עם תעודה',
    halal_meat: 'חלאל ',
    halal_alcohol_free_options: 'מנות ללא אלכוהול',
  },
  certified_kitchen_kosher: 'מטבח כשר - חייב תעודה',
  self_claim_kitchen_kosher: 'מטבח כשר - לא חייב תעודה',
  kosher_dish: 'מנות כשרות בלבד',
  kosher_but_mixed_meat_dairy: 'מנות כשרות אבל חלב ובשר סבבה',
  no_faith_restriction: 'ללא הגבלה',
  no_restrictions: 'אין לי הגבלות מיוחדות',
  soon: 'בקרוב',
  have_any_questions: 'יש שאלות?',
  contact_us: 'דברו איתנו',
  powered_by: 'מופעל על ידי',
  avoidORReduce_dish_basic_ingredients_contain: 'המנה מכילה רכיבים שאת\\ה מנסה להימנע מהם',
  avoidORReduce_dish_choices_ingredients_contain: 'המנה מכילה רכיבים לבחירה שאת\\ה מנסה להימנע מהם',
  avoidORReduce_dish_mandatory_ingredients_contain: 'המנה מכילה רכיבים שאת\\ה מנסה להימנע מהם',
  dish_not_suited_to_faith_restriction: 'המנה לא תואמת לכשרות שלך',
  welcome: {
    morning: 'בוקר טוב',
    afternoon: 'אחה"צ טובים',
    evening: 'ערב טוב',
    night: 'לילה טוב',
    to: 'ברוכים הבאים ל-',
  },
  all_dishes: 'כל המנות',
  my_dishes: 'תפריט אישי',
  i_desire: 'בא לי',
  account: 'משתמש',
  reset_to_all: 'איפוס להכל',
  login_step: {
    title: 'חיבור ל-EAT ID שלך',
    subtitle: '',
    register: 'ברור! אין לי כוח למלא את זה שוב',
    optout: 'פעם אחרת',
  },
  register_step: {
    title: 'שנשמור לך את ה-EAT ID?',
    subtitle: 'זה יחסוך לך זמן פעם הבאה שנפגש',
  },
  got_it: 'הבנתי',
  cookingMethodsOrFlavors: 'סוגי בישול וטעמים',
  dishTypeFoodGroup: 'סוגי מנות',
  recalculating_dishes: 'מחשבים מחדש מנות בשבילך',
  how_eatid_works: 'איך EAT ID עובד?',
  eattlv: {
    contestBtn: 'מנה עלינו?',
    contestTitle: 'רוצה מנה חינם עלינו?',
    step1: 'השתמשו במלצר הוירטואלי והרשמו',
    step2: 'העלו תמונה שלכם מהפסטיבל ותייגו',
    step3: 'ספרו במשפט על אירוע מצחיק שקרה בגלל מונח אוכל שלא הכרתם או תיאור מנה לא ברור',
  },
  under_the_terms: 'בכפוף לתקנון',
  onboardingBtn: 'EAT ID',
  onboardingWhyShouldI: 'למה כדאי לך להירשם?',
  organization_admins: 'מנהלי מערכת הארגון',
  language_default: 'שפת ברירת המחדל',
  dishType: 'סוג מנה',
  date: 'תאריך',
  time: 'שעה',
  order: 'סדר',
  order_dishes: 'סידור המנות',
  order_categories: 'סידור הקטגוריות',
  custom_order_dishesInstances: 'סידור המנות מותאם אישית',
  reset_to_default_dishes_order: 'סידור ברירת מחדל',
  missing_translation: 'חסר תרגום',
  now_offering: 'מוצע עכשיו',
  anytime_offering: 'הכל',
  edit: 'עריכה',
  multi_edit: 'עריכה מרובה',
  edit_eat_id: 'עריכת ה-EAT ID שלך',
  service_menus: 'תפריטים',
  should_replace_or_add: 'האם לבצע דריסה או הוספה לערכים אלו?',
  replace: 'דריסה',
  share_your_personalized_menu: 'שיתוף תפריט אישי',
  copy_share_personalized_menu_link: 'העתקת לינק שיתוף תפריט אישי',
  copied_to_clipboard: 'הועתק ללוח כתיבה',
  chosen_components: 'רכיבים שנבחרו',
  not_chosen_yet: 'טרם נבחרו',
  no_results: 'לא נמצאו תוצאות לחיפוש',
  add_new_component: 'הוספת מרכיב חדש',
  customer_ingredient_creation_warning: 'מרכיבים חדשים נדרשים לעבור וריפיקציה של מנהל אדמין ולכן ייתכנו שינויים במידעמ הרכיב',
  desirable_foodGroups: 'תיוג קבוצות אוכל',
  categories_and_tags: 'קיטלוג ותיוג',
  ids_and_makatim: 'מזהים ומק"טים',
  additional_information: 'מידע נוסף',
  add_new_category: 'הוספת קטגוריה חדשה: ',
  deleted_category: 'קטגוריה שנמחקה',
  desirable_foodGroups_helpers: 'תיוג מנות יאפשר לסועדים לאתר מנות המתאימות להם בצורה מדוייקת יותר לרצונותיהם. המלצה שלנו, תייגו כמה שיותר תיוגים המתאימים למנה',
  externalSystems: 'מערכות חיצוניות',
  export_to_excel: 'ייצוא ל-CSV',
  amount: 'כמות',
  type: 'סוג',
  verified: 'מנה מאומתת לכל רכיביה',
  mark_as_new: 'סימון כ-"חדש"',
  mark_as_recommended: 'סימון כ-"מומלץ"',
  is_sidedish: 'סימון כמנת צד',
  recommended: 'מומלץ',
  previous_price: 'מחיר קודם',
  previous_price_change: 'תאריך התחלה של מחיר קודם',
  new_price: 'מחיר נוכחי',
  latest_price_change: 'תאריך התחלה של המחיר הנוכחי',
  added_dish_instances: 'המנות הוספו בהצלחה',
  multi_add_dishes: 'הוספה מרובה של מנות',
  secrect_recipe_question: 'האם תרצו לשמור על המתכון כסודי?',
  secret: 'סודי',
  added_by_customer: 'נוסף על ידי לקוח',
  missing_ingredients: 'חסרים רכיבים',
  foodGroup: 'קבוצת אוכל',
  parentFoodGroups: 'תחת קבוצות אוכל',
  isSearchable: 'בר חיפוש',
  isDishSearchable: 'בר חיפוש במנה',
  isCookingMethodsOrFlavors: 'שיטות בישול/טעמים',
  isGeneric: 'מרכיב גנרי',
  manufacturer: 'יצרן',
  menu_dishes: 'מנות התפריט',
  edit_categories: 'עריכת קטגוריות',
  intent: {
    label: 'ייעוד',
    sitdown: 'ישיבה',
    delivery: 'משלוח',
    pickup: 'איסוף עצמי',
  },
  reject_customer_ingredient: {
    title: 'רכיב לא מאושר',
    move_to_recipes: 'העברה למתכונים של הלקוח',
    replace_with_another_ingredient: 'החלפה לרכיב קיים אחר',
  },
  affected_food_components: 'מרכיבי אוכל מושפעים',
  menuInstances_controller: 'מנהל תפריטי סרוויס',
  menuInstance_dialog: {
    title: 'מופע תפריט לסרוויס',
    reoccurring: 'חזרתיות התפריט',
    singleInstance: 'רק מופע זה',
    allInstances: 'כל המופעים',
    nowAndAfterInstances: 'מהמופע הזה והלאה',
    reoccurring_save_title: 'עדכון מופעי תפריט מחזוריים',
    reoccurring_delete_title: 'מחיקת מופעי תפריט מחזוריים',
    singleInstance_texthelper: 'עדכון מופע התפריט הספציפי ינתק אותו משאר המופעים המחזוריים',
    nowAndAfterInstances_texthelper: 'מופע זה והלאה ייצרו סט מופעים מחזוריים נפרד מהמחזוריות הנוכחית',
  },
  diner_doesnt_exist: 'מספר לא קיים',
  diner_with_phonenumber_given_does_not_exist: 'מספר לא קיים',
  temperary_dish_tags: {
    label: 'תגיות זמניות',
    vegan: 'טבעוני',
    canBeVegan: ' יכול להיות טבעוני',
    warning: 'תגיות אלו זמניות וכנראה ימחקו בהמשך... הם נועדו לייצוא התפריט באקסל',
  },
  menuInstance_controller_new_kitchen_warning: 'יש לשמור את המטבח חדש כדי לאפשר לנהל את התפריטי סרוויס',
  dinerQuestions: {
    excludeFoodComponent: 'האם את\\ה לא מקרב\\ת לצלחת שלך:',
    foodComponentOpinion: 'מה דעתך על: '
  },
  love_it: 'אוהב\\ת מאוד',
  hate_it: 'שונא\\ת מאוד',
  depends: 'תלוי',
  on_premise_days: {
    question: 'באילו ימים את/ה בדרך כלל מגיע/ה למשרד?',
    never: 'אף פעם',
    everyday: 'כל יום',
    changes_weekly: 'משתנה כל שבוע',
  },
  lunch_meal_source: {
    question: 'איפה את/ה מעדיף/ה בדרך כלל לאכול ארוחת צהריים?',
    options: {
      in_the_office_cafe: 'בהסעדה שבמשרד',
      at_the_restaurants_near_the_office: 'במסעדות שליד המשרד',
      bring_food_from_home: 'אני מביא/ה אוכל מהבית',
      order_food_for_delivery: 'בהזמנת משלוח',
      depends_on_what_the_cafe_is_serving_that_day: 'תלוי בתפריט באותו יום',
    }
  },
  lunch_meal_wishlist: {
    question: 'האם יש משהו שיכול לשפר את שירות ההסעדה בבניין',
    placeholder: 'נשמח לקבל רעיונות לשיפור השירות',
  },
  proteins_options: {
    questions: {
      'main_proteins': 'מה החלבון המועדף עלייך בדרך כלל?',
      'main_proteins_breakfast': 'מה החלבון המועדף עלייך בארוחת בוקר?',
      'main_proteins_lunch': 'מה החלבון המועדף עלייך בארוחת צהריים?',
      'main_proteins_dinner': 'מה החלבון המועדף עלייך בארוחת ערב?',
    },
    grain_based: 'על בסיס חיטה (סייטן)',
    legumes: 'על בסיס קטניות (אפונה \\ שעועית)',
    soy_based: 'על בסיס סויה (טופו)',
    fish: 'דגים',
    no_preference: 'אין העדפה מיוחדת',
    dont_eat_plant_based_substiudes: 'לא אוכל\\ת תחליפים מהצומח',
    chicken: 'עוף',
    red_meat: 'בשר בקר',
    plant_based_substiude: 'חלבון מן הצומח',
    none: 'אף אחד מהם',

  },
  carbs_options: {
    questions: {
      'main_carbs': 'מה הפחמימה המועדפת עלייך בדרך כלל?',
      'main_carbs_breakfast': 'מה הפחמימה המועדפת עלייך בארוחת בוקר?',
      'main_carbs_lunch': 'מה הפחמימה המועדפת עלייך בארוחת צהריים?',
      'main_carbs_dinner': 'מה הפחמימה המועדפת עלייך בארוחת ערב?',
      'breads_type': 'מהם סוגי הלחם המועדפים עלייך בדרך כלל?',
    },
    rice: 'אורז',
    pasta: 'פסטה',
    bread: 'לחם',
    potatoes: 'תפוחי אדמה',
    no_preference: 'אין העדפה מיוחדת',
    none: 'אף אחד מהם',
    white_bread: 'לחם לבן',
    whole_grain_bread: 'לחם מלא',
    multigrain_bread: 'לחם דגנים',
    flatbreads: 'לחם שטוח',
  },
  breakfast_options: {
    questions: {
      'breakfast':'מהן סוגי ארוחת הבוקר המועדפות עליך?',
      'eggs_form': 'איזו צורת הכנה של ביצים מועדפת עלייך?',
      'dairy': 'איזה סוגי גבינות מועדפות עליך?',
    },
    light_breakfast: 'ארוחת בוקר קלה',
    savory_breakfast: 'ארוחת בוקר ישראלית',
    sweet_breakfast: 'פנקייקים, וופל, קורנפלקס',
    bread_based: 'סנדוויצים, טוסטים',
    hard_boiled: 'ביצה קשה',
    scrambled: 'ביצה מקושקשת',
    omelets: 'חביתה',
    shakshuka: 'שקשוקה',
    poached: 'ביצה עלומה',
    fried: 'ביצת עיין, ביצה הפוכה',
    baked: 'קיש, סופלה, פשטידה',
    hard_cheeses: 'גבינות קשות',
    semi_hard_cheeses: 'גבינות חצי קשות (פטה, ברי)',
    soft_cheeses: 'גבינות רכות',
    blue_cheeses: 'גבינות כחולות',
    no_preference: 'אין העדפה מיוחדת',
    none: 'אף אחד מהם',
  },
  desserts_options: {
    questions: {
      'desserts': 'מה הקינוח המועדף עלייך בדרך כלל?',
      'desserts_breakfast': 'מה הקינוח המועדף עלייך בארוחת בוקר?',
      'desserts_lunch': 'מה הקינוח המועדף עלייך בארוחת צהריים?',
      'desserts_dinner': 'מה הקינוח המועדף עלייך בארוחת ערב?',
    },
    cake: 'עוגות',
    fruit: 'פירות',
    pastry: 'מאפים',
    ice_cream: 'גלידות',
    no_preference: 'אין העדפה מיוחדת',
    none: 'אף אחד מהם',
  },
  choose_multiple_options: 'ניתן לבחור יותר מאחד',
  dinerEatIDNotes: {
    question: 'עוד משהו שאנחנו צריכים לדעת על העדפות שלך באוכל?',
    subtitle: 'אפשר לכתוב כל דבר שרק עולה לך, כדי שנוכל לספק לך את החוויה הטובה ביותר!',
  },
  signupStep: {
    title: 'תצטרפו אלינו להגדיר מחדש את עולם ההסעדה',
    subtitle: 'הירשמו להנות מחוויה קולינרית עתידית',
  },
  most_common: 'הכי שכיח',
  search_results: 'תוצאות החיפוש',
  analytics_page: {
    dish_match_list: 'רשימת התאמות מנות-סועדים',
    diners_chart: 'דיאטות הסועדים',
    mostExcludedDinersFoodComponents: 'מרכיבים הכי שנואים על סועדים',
    brand_most_occuring_food_components: 'מרכיבים שכיחים ביותר',
    brand_most_occuring_specific_ingredients: 'מרכיבים',
    brand_most_occuring_foodGroups: 'קבוצות אוכל',
    dish_match_allergies_and_Diets: 'אלרגיות ודיאטות במנות',
    dish_excluded_in_diet: 'לא מתאים לדיאטה',
    dish_name: 'שם מנה',
    dish_allrgens: 'אלרגיות',
    dish_matches: 'התאמה',
    probability: 'הסתברות',
    recommendation: 'המלצה',
    categoryDishType: 'סוג קטגורית המנה',
    categories: 'קטגוריות',
    restrictedRegulars: 'קבועים ללא הגבלות',
    unrestrictedRegulars: 'קבועים עם הגבלות',
    occasionalMenuDrivenFlexibles: 'תלוי בתפריט',
    nonCafetiria: 'אוכלים בחוץ',
    missed: 'חסר'
  },
  kitchen_texts: {
    label: 'טקסטים של מטבחים',
    onPremise: 'במקום ההסעדה',
    kitchenOnPremiseTitle: 'בואו נראה רק את המנות שמתאימות לך',
    kitchenOnPremiseSubtitle: '',
    kitchenRegistration: 'עמוד הרשמה מוקדמת למטבח',
    kitchenRegistrationTitle: 'בואו נראה רק את המנות שמתאימות לך',
    kitchenRegistrationSubtitle: '',
    registrationCompleted: 'עמוד הרשמה הושלמה',
    kitchenRegistrationCompletedTitle: 'תודה שנרשמת!',
    kitchenRegistrationCompletedSubtitle: 'נתראה באירוע',
  },
  questionnaire_texts: {
    label: 'טקסטים של השאלון',
    questionnaireRegistrationStep: 'שלב הרשמה למערכת בשאלון',
    questionnaireRegistrationStepTitle: 'שנשמור את ה-EAT ID שלך?',
    questionnaireRegistrationStepSubtitle: 'נחסוך לך זמן לפעם הבאה שנתראה',
    questionnaireLoginStep: 'שלב התחברות בשאלון',
    questionnaireLoginStepTitle: 'חיבור ל-EAT ID שלך',
    questionnaireLoginStepSubtitle: '',
    questionnairePhonenumberStep: 'שלב מספר טלפון בשאלון',
    questionnairePhonenumberStepTitle: 'מה מספר הטלפון שלך?',
    questionnairePhonenumberStepSubtitle: '',
  },
  site_texts: {
    label: 'טקסטים של site',
    kitchensListTitle: 'רשימת המסעידים',
    kitchensListSubtitle: '',
    onPremise: 'במקום ההסעדה',
    siteOnPremiseTitle: 'בואו נראה רק את המנות שמתאימות לך',
    siteOnPremiseSubtitle: '',
    siteRegistration: 'עמוד הרשמה מוקדמת למטבח',
    siteRegistrationTitle: 'בואו נראה רק את המנות שמתאימות לך',
    siteRegistrationSubtitle: '',
    registrationCompleted: 'עמוד הרשמה הושלמה',
    siteRegistrationCompletedTitle: 'תודה שנרשמת!',
    siteRegistrationCompletedSubtitle: 'נתראה באירוע',
    siteAttendanceTitle: 'מתי את\\ה מגיע\\ה אלינו?',
    siteAttendanceSubtitle: '',
    siteAttendanceCompletedTitle: 'תודה על עדכון ההגעה שלך!',
    siteAttendanceCompletedSubtitle: 'אם משהו משתנה, תמיד אפשר לעדכן אותנו מאוחר יותר',
  },
  showQuestionnairePhonenumberStep: 'שלב בקשת מספר טלפון בהרשמה מוקדמת',
  disableRegistrationCompletionToMenuButton: 'הורדת כפתור מעבר לתפריט במסך השלמת הרשמה',
  forceRegistration: 'חייב הרשמה',
  questionnaire_section: 'הגדרות שאלון',
  questionnaireSteps: 'שלבים בשאלון',
  accumulatedFoodComponents: 'כלל המרכיבים',
  foodcomponent_not_recognized: 'הפריט לא מוכר וחסר מידע',
  foodcomponent_not_recognized_action_text: 'כדי למלא את המידע החסר, ניתן ליצור לו:',
  made_here: 'מתכון חדש',
  bought_inventoryproduct: 'מוצר חדש מהמחסן',
  ingredientGroup: {
    label: 'קבוצת מרכיבים',
    includesCombinations: 'קומבינציות כלולות',
    excludesCombinations: 'קומבינציות לא כלולות',
  },
  why_are_we_asking: 'למה אנחנו שואלים?',
  on_premise_days_step_explanation: 'לדעת מתי תגיעו, יעזור להתאים את כמות ומגוון המזון שאנחנו המיוצרים בדיוק למי שמגיע , כך נוכל לצמצם את בזבוז מזון',
  lunch_meal_source_step_explanation: 'אנו רוצים ליצור תפריט שמתאים לצרכים של מירב הסועדים, כך שלא תצטרך ללכת לשום מקום אחר כדי למצוא אוכל שמתאים לך. איפה אתה מעדיף לאכול עכשיו יעזור לנו להבין את זה.',
  phonenumber_step_explanation: 'מספר הטלפון שלך היא הדרך הפשוטה ביותר בשבילנו לזהות אותך בפעם הבאה שנפגש',
  restrictions_step_explanation: 'אנחנו מבקשים לדעת על כל סוגי העדפות המזון שלך כדי שנוכל להציג לך רק את מה שרלוונטי לך! המידע שמועבר למסעיד הוא רק לגבי איזה מנות מתאימות לך, לא למה! המידע על הסיבות נשמר בצורה מאובטחת ולא משותף.',
  to_the_menu: 'לתפריט',
  no_menu_available_now: 'עדיין אין תפריט ליום הזה, אנא נסו במועד מאוחר יותר',
  now: 'עכשיו',
  search_something_above: 'ניתן לחפש כל דבר - באמת! רק צריך לחפש ולהוסיף לרשימה שלך!',
  total_diners: 'מספר סועדים',
  morning_conference_preferences: {
    question: "מה העדפתך לגבי אוכל בזמן ההתכנסות?",
    fruits: "פירות",
    vegetables: "ירקות טריים",
    sweet_pastries: "מאפים מתוקים",
    savory_pastries: "מאפים מלוחים",
    sandwitches: "כריכים",
    no_preference: "לא משנה לי",
    none: "לא אין צורך",
  },
  conference_breaks_preferences: {
    question: "בזמן ההפסקות...",
    fruits: "פירות",
    vegetables: "ירקות טריים",
    sweet_pastries: "מאפים מתוקים",
    savory_pastries: "מאפים מלוחים",
    sandwitches: "כריכים",
    no_preference: "לא משנה לי",
    none: "אין צורך, באתי לנטוורקינג",
  },
  wishlist_placeholder: 'ניתן להזין כל מה שעולה לראשך!',
  ingredientType: 'סוג רכיב',
  baseIngredient: 'מרכיב בסיסי',
  manufacturedProduct: 'מוצר קנוי',
  foodType: 'סוג מאכל',
  DinersDietsEulerDiagram: 'דיאטות הסועדים',
  foodGroupType: 'סוג קבוצת אוכל',
  foodGroupTypes: {
    ingredientsAggregator: 'אגריגציה של רכיבים',
    foodTypesAggregator: 'אגריציה של קבוצות אוכל',
    cookingMethod: 'שיטת בישול',
    questions: 'שאלות',
    dishType: 'סוג מאכל',
    flavor: 'טעם',
  },
  originalContainsLabel: 'רשימת רכיבים המקורים',
  calculatedDependantFoodComponents: 'מתכונים ומנות התלויים במרכיב זה',
  calculating: 'מחשב',
  calculate: 'חשב',
  site_service_time: 'זמן סרביס',
  add_to_order: 'הוספה להזמנה',
  cart: {
    title: 'הזמנה חדשה',
    changeRequest: {
      remove: 'להסיר',
      putAside: 'בצד',
    },
    edit_dish: 'עריכה',
    remove_from_cart: 'הסרה',
  },
  foodComponents_not_found_title: 'לא נמצא מה שחיפשת?',
  foodComponents_not_found_message: 'ייתכן שאנחנו לא תומכים בזה עדיין. אבל ניתן להוסיף את מה שחיפשת ונדאג לתמוך בזה בהקדם!',
  request_new_restriction: 'בקשת הגבלה חדשה',
  no_results_found_restrictions_options: 'לא נמצאו תוצאות לחיפוש זה',
  diner_created_foodComponent_warning: 'מרכיבים שנוצרו על ידך צריכים לעבור אימות לפני שניתן להתחשב בהם בהעדפות שלך.',
  days: {
    long: {
      sunday: 'ראשון',
      monday: 'שני',
      tuesday: 'שלישי',
      wednesday: 'רביעי',
      thursday: 'חמישי',
      friday: 'שישי',
      saturday: 'שבת',
    },
    short: {
      sunday: 'יום א\'',
      monday: 'יום ב\'',
      tuesday: 'יום ג\'',
      wednesday: 'יום ד\'',
      thursday: 'יום ה\'',
      friday: 'יום ו\'',
      saturday: 'יום ש\'',
    }
  },
  attending: 'מגיע\\ה',
  notAttending: 'לא מגיע\\ה',
  serviceManagement: {
    dish: {
      verified: 'מוגש כמתוכנן',
      ingredient_changes: 'שינויים ברכיבים',
      switched: 'המנה הוחלפה',
      removed: 'המנה הוסרה',
      isAvailable: 'זמין',
      isNotAvailable: 'לא זמין',
      units: {
        plates: 'צלחות',
        gastronoms: 'גסטרונומים',
        pieces: 'חתיכות',
        kg: 'ק"ג',
      }
    }
  },
  dishesSlotsNumber: 'מספר מנות לקטגוריה',
  allDay: 'כל היום',
  add_availableMenu: "הוספת תפריט זמין",
  service_manager_week: 'שבוע',
  servicesManager: {
    availableMenus: 'תפריטים זמינים',
    pre_service: 'לפני הסרביס',
    during_service: 'בזמן סרביס',
    after_service: 'אחרי הסרביס',
    copy_from_last_week: 'העתק מהשבוע שעבר',
  },
  related_diners: 'סועדים מקושרים אליי',
  diner_back_to_me: 'חזרה לפרופיל שלי',
  add_diner: 'הוספת פרופיל סועד',
  diner_nickname: 'כינוי',
  randomize_avatar: 'אווטאר אקראי',
  is_verified: 'מאומת',
}