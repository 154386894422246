import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { useOrganization } from 'contexts/organization'
import { getQuery } from 'api/menus'

export const useMenus = (
  options = {
    page: 0,
    search: '',
    brandId: null,
    availableMenus: false
  }
) => {
  const { organizationId } = useOrganization()
  const { brandId, page, search, rowsPerPage, availableMenus } = options
  const query = getQuery({
    organizationId,
    brandId,
    page,
    search,
    rowsPerPage,
    availableMenus,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/menus/${query}`, fetcher)

  return {
    menus: data?.menus,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useMenuById = (menuId) => {
  const { data, error, ...rest } = useSWR(menuId && `/api/v2/menus/${menuId}`,
    fetcher
  )

  return {
    menu: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useMenusByBrandId = (brandId) => {
  const { data, error, ...rest } = useSWR(`/api/v2/menus?brandId=${brandId}`,
    fetcher
  )

  return {
    menus: data?.menus,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useMenusByKitchenId = (kitchenId) => {
  const { data, error, ...rest } = useSWR(kitchenId && `/api/v2/menus?kitchenId=${kitchenId}`,
    fetcher
  )

  return {
    menus: data?.menus,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
