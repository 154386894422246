import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material'
import axios from 'axios'
import _keyBy from 'lodash/keyBy'
import LoadingRelativePage from 'components/Loaders/LoadingRelativePage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { grey } from '@mui/material/colors'


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function DishMatchList({ query }) {
  const { t } = useTranslation()
  const dishesMatchAbortController = React.useRef()
  const dishesDataAbortController = React.useRef()

  const [dishesInfo, setDishInfo] = React.useState(null)

  const [dishMatches, setDishMatches] = React.useState(null)
  const [, setPredictions] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [totalDiners, setTotalDiners] = React.useState(0)


  React.useEffect(() => {
    setError(null)

    const handleClickSearch = async () => {
      const { brandId, dinersAmount, kitchenId, date, menuId, isDemo } = query
      setError(null)
      setIsLoading(true)
      try {
        dishesMatchAbortController.current = new AbortController()
        dishesDataAbortController.current = new AbortController()

        const dishMatchesQuery = [
          brandId && `brandId=${brandId}`,
          isDemo && dinersAmount && `dinersAmount=${dinersAmount}`,
          kitchenId && `kitchenId=${kitchenId}`,
          date && `date=${date}`,
          isDemo && 'isDemo=true',
          menuId && `menuId=${menuId}`,
        ]
          .filter(Boolean)
          .join('&')
        const {
          data: { matches: dishMatchesData, totalDiners: newTotalDiners, prediction: predictionData  },
        } = await axios.get(`/api/v2/analytics/dishMatch?${dishMatchesQuery}`, {
          signal: dishesMatchAbortController.current.signal,
        })

        const dishInfoQuery = [brandId && `brandId=${brandId}`]
          .filter(Boolean)
          .join('&')
        const { data: dishesInfoData } = await axios.get(
          `/api/v2/analytics/dishInfo?${dishInfoQuery}`,
          {
            signal: dishesMatchAbortController.current.signal,
          }
        )
        setDishInfo(_keyBy(dishesInfoData, '_id'))

        setTotalDiners(newTotalDiners)
        setDishMatches(dishMatchesData)
        setPredictions(predictionData)
      } catch (err) {
        setError(err)
      }
      setIsLoading(false)
    }
    handleClickSearch()

    return () => {
      dishesMatchAbortController.current?.abort()
      dishesDataAbortController.current?.abort()
    }
  }, [query])

  const dishesMatchesList = React.useMemo(
    () =>
      Object.keys(dishMatches || {}).sort((a, b) => {
        return dishMatches[a] > dishMatches[b] ? 1 : -1
      }),
    [dishMatches]
  )

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        m: 1,
        overflow: 'auto',
        bgcolor: grey[100],
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h5">
        {t('analytics_page.dish_match_list')}
      </Typography>
      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {error && !dishMatches && <Typography>{t('fetch_error')}</Typography>}
        {isLoading && (
          <Stack
            sx={{
              width: 200,
            }}
          >
            <LoadingRelativePage />

            <Button
              variant="contained"
              color="error"
              sx={{
                mt: 2,
              }}
              onClick={() => dishesMatchAbortController.current.abort()}
            >
              {t('cancel')}
            </Button>
          </Stack>
        )}
        {!isLoading && dishMatches && (
          <>
            <TableContainer component={Paper} sx={{ flex: 1 }}>
              <Table stickyHeader>
                <TableHead>
                  <StyledTableRow>
                    <TableCell>{t('analytics_page.dish_name')}</TableCell>
                    <TableCell>{t('analytics_page.dish_matches')}</TableCell>
                    <TableCell>{t('analytics_page.probability')}</TableCell>
                    <TableCell>{t('analytics_page.recommendation')}</TableCell>
                    <TableCell>
                      {t('analytics_page.categoryDishType')}
                    </TableCell>
                    <TableCell>{t('analytics_page.categories')}</TableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {dishesMatchesList.map((dishId) => (
                    <StyledTableRow
                      key={dishId}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {dishesInfo?.[dishId]?.operationalName}
                      </TableCell>
                      <TableCell>
                        {totalDiners &&
                          `${Math.round(
                            100 * (dishMatches[dishId] / totalDiners)
                          )}% ${t('match')} / ${dishMatches[dishId]} ${t(
                            'diners'
                          )}`}
                      </TableCell>
                      <TableCell>
                        {t(dishesInfo?.[dishId].categorizedDishType) || '-'}
                      </TableCell>

                      <TableCell>
                        {dishesInfo?.[dishId]?.categoriesFullData
                          ?.map((c) => c.operationalName)
                          ?.join(', ') || '-'}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              direction={'row'}
              spacing={4}
              sx={{
                alignItems: 'start',
                width: '100%',
                mt: 2,
              }}
            >
              <Typography variant="h6">
                dishes: {dishesMatchesList?.length}
              </Typography>

              <Typography variant="h6">diners: {totalDiners}</Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Paper>
  )
}
