import { Button, Paper, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import axios from 'axios'
import LoadingRelativePage from 'components/Loaders/LoadingRelativePage'
import { useUser } from 'contexts/user'
import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DAYS_NAMES } from 'utils/datesDaysAndTimes'
import DinersClasificationPieChart from './DinersClasificationPieChart'
import DinersClasificationByDayBarChart from './DinersClasificationByDayBarChart'
import DinersTable from './DinersTable'

const RELEVANT_DAYS = DAYS_NAMES.slice(1, 6)

const INITIAL_DINERS_COUNTER_BY_DAY_NAME = RELEVANT_DAYS.reduce(
  (acc, dayName) => {
    return {
      ...acc,
      [dayName]: 0,
    }
  },
  {}
)

export default function DinersCharts({
  brandId,
  kitchenId,
}) {
  const { t } = useTranslation()
  const dataAbortController = React.useRef()
  const [diners, setDiners] = React.useState([])
  const [foodComponentsDict, setFoodComponentsDict] = React.useState({})
  const [error, setError] = React.useState(null)
  const [date] = React.useState(new Date())
  const [isLoading, setIsLoading] = React.useState(false)
  const { isAdmin } = useUser()

  const datesRange = React.useMemo(() => {
    const range = [date]
    for (let i = 0; i < 6; i++) {
      range.push(new Date(new Date(range[i]).setDate(range[i].getDate() + 1)))
    }
    return range
  }, [date])

  React.useEffect(() => {
    const getDiners = async () => {
      try {
        setIsLoading(true)
        setError(false)
        dataAbortController.current = new AbortController()
        const dinersQuery = [
          brandId && `brandId=${brandId}`,
          kitchenId && `kitchenId=${kitchenId}`,
          date && `date=${date}`,
        ]
          .filter(Boolean)
          .join('&')
        const {
          data: { diners, foodComponentsNames },
        } = await axios.get(`/api/v2/analytics/diners?${dinersQuery}`, {
          signal: dataAbortController.current.signal,
        })
        setDiners(diners)
        setFoodComponentsDict(foodComponentsNames)
      } catch (err) {
        setError(err)
      }
      setIsLoading(false)
    }
    getDiners()

    return () => dataAbortController.current?.abort()
  }, [brandId, kitchenId])

  const dinersPerDayExpectation = React.useMemo(() => {
    return diners?.reduce((acc, diner) => {
      if (diner?.preferences?.on_premise_days?.includes('everyday')) {
        const newAcc = { ...acc }

        RELEVANT_DAYS.forEach((dayName) => {
          newAcc[dayName] = newAcc[dayName] + 1
        })
        return newAcc
      }

      if (
        !diner?.preferences?.on_premise_days?.includes('changes_weekly') &&
        !diner?.preferences?.on_premise_days?.includes('everyday')
      ) {
        const newAcc = { ...acc }
        diner?.preferences?.on_premise_days?.forEach((day) => {
          newAcc[day] = newAcc[day] + 1
        })
        return newAcc
      }
      return acc
    }, INITIAL_DINERS_COUNTER_BY_DAY_NAME)
  }, [diners])

  const dinersClassification = React.useMemo(() => {
    return diners.reduce(
      (acc, diner) => {
        if (
          !diner?.preferences?.lunch_meal_source?.includes(
            'in_the_office_cafe'
          ) &&
          !diner?.preferences?.lunch_meal_source?.includes(
            'depends_on_what_the_cafe_is_serving_that_day'
          )
        ) {
          return {
            ...acc,
            nonCafetiria: [...acc.nonCafetiria, diner],
          }
        }

        if (
          diner?.preferences?.lunch_meal_source?.includes(
            'depends_on_what_the_cafe_is_serving_that_day'
          )
        ) {
          return {
            ...acc,
            occasionalMenuDrivenFlexibles: [
              ...acc.occasionalMenuDrivenFlexibles,
              diner,
            ],
          }
        }

        const doesDinerHasFilterRestrictions =
          diner.filters?.diets?.length > 0 ||
          diner.filters?.allergies?.length > 0 ||
          diner.filters?.exclude?.length > 0
        if (
          diner?.preferences?.lunch_meal_source?.includes(
            'in_the_office_cafe'
          ) &&
          doesDinerHasFilterRestrictions
        ) {
          return {
            ...acc,
            restrictedRegulars: [...acc.restrictedRegulars, diner],
          }
        }

        if (
          diner?.preferences?.lunch_meal_source?.includes(
            'in_the_office_cafe'
          ) &&
          !doesDinerHasFilterRestrictions
        ) {
          return {
            ...acc,
            unrestrictedRegulars: [...acc.unrestrictedRegulars, diner],
          }
        }
        return { ...acc, missed: [...acc.missed, diner] }
      },
      {
        unrestrictedRegulars: [],
        restrictedRegulars: [],
        occasionalMenuDrivenFlexibles: [],
        nonCafetiria: [],
        missed: [],
      }
    )
  }, [diners])

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        m: 1,
        overflow: 'auto',
        bgcolor: grey[100],
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        sx={{
          flex: 1,
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        {!diners && error && <Typography>{t('fetch_error')}</Typography>}
        {isLoading && (
          <Stack
            sx={{
              width: 200,
            }}
          >
            <LoadingRelativePage />

            <Button
              variant="contained"
              color="error"
              sx={{
                mt: 2,
              }}
              onClick={() => dataAbortController.current.abort()}
            >
              {t('cancel')}
            </Button>
          </Stack>
        )}

        {!isLoading && diners && (
          <Stack
            sx={{
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography variant="h2">{diners.length}</Typography>
            <Typography variant="h6">{t('total_diners')}</Typography>

            <Stack>
              <Typography>expected:</Typography>
              <Stack
                direction={'row'}
                sx={{
                  gap: 2,
                }}
              >
                {datesRange.map((specificDate) => (
                  <Stack
                    key={specificDate.toISOString()}
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant={'h4'}>
                      {dinersPerDayExpectation[
                        format(specificDate, 'EEEE').toLocaleLowerCase()
                      ] || 0}
                    </Typography>
                    <Typography>{format(specificDate, 'EEEE')}</Typography>
                    <Typography variant={'body2'}>
                      {format(specificDate, 'dd/MM/yyyy')}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>

            {isAdmin && (
              <DinersTable
                diners={diners}
                foodComponentsDict={foodComponentsDict}
              />
            )}
          </Stack>
        )}
      </Stack>
      <Stack direction={'row'}>
        <DinersClasificationPieChart
          diners={diners}
          dinersClassification={dinersClassification}
        />

        <DinersClasificationByDayBarChart
          dinersClassification={dinersClassification}
        />
      </Stack>
    </Paper>
  )
}
